import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"

import Hero from "../components/templates/common/Hero"
import CleanContent from "../components/templates/common/CleanContent"
import FourSquareLinks from "../components/templates/financialInfo/FourSquareLinks"
import ReadyHero from "../components/templates/common/ReadyHero"

const FinancialInformation = props => {
  const { hero, cleanContent, squareLinks, readyHero, seoInfo } = props.data

  const heroData = hero.template.templatePageFinancialInfo
  const cleanContentData = cleanContent.template.templatePageFinancialInfo
  const squareLinksData = squareLinks.template.templatePageFinancialInfo
  const readyHeroData = readyHero.template.templatePageFinancialInfo

  return (
    <Layout>
      <Seo
        title={seoInfo.seoFields.swbThemeMetaTitle}
        description={seoInfo.seoFields.swbThemeDescription}
        //metaImg={seoInfo.seoFields.swbThemeImage.localFile.relativePath}
        location={props.location.pathname}
      />
      <Hero data={heroData} />
      <CleanContent data={cleanContentData} />
      <FourSquareLinks data={squareLinksData} />
      <ReadyHero data={readyHeroData} />
    </Layout>
  )
}

export const financialInfoTempQuery = graphql`
  query financialInfoTempPage($id: String!) {
    seoInfo: wpPage(id: { eq: $id }) {
      seoFields {
        swbThemeDescription
        swbThemeMetaTitle
        swbThemeImage {
          localFile {
            relativePath
          }
        }
      }
    }

    hero: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_FinancialInformation {
          templatePageFinancialInfo {
            heroImage {
              altText
              sourceUrl
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(width: 2500)
                }
              }
            }

            heroLogo {
              altText
              sourceUrl
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(width: 1500)
                }
              }
            }
          }
        }
      }
    }

    cleanContent: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_FinancialInformation {
          templatePageFinancialInfo {
            cleanContentTitle
            cleanContentParagraphs
          }
        }
      }
    }

    squareLinks: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_FinancialInformation {
          templatePageFinancialInfo {
            squares {
              title
              content
              image {
                altText
                sourceUrl
                localFile {
                  url
                  childImageSharp {
                    gatsbyImageData(width: 1500)
                  }
                }
              }
            }
          }
        }
      }
    }

    readyHero: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_FinancialInformation {
          templatePageFinancialInfo {
            readyHeroTitle
            readyHeroContent
            readyHeroButtonText
            readyHeroButtonSlug
            readyHeroImage {
              altText
              sourceUrl
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(width: 2500)
                }
              }
            }
          }
        }
      }
    }
  }
`

export default FinancialInformation
