import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { B2LightBlue, H2Blue, standardWrapper } from "../../../styles/helpers"

import BackgroundOne from "../../Icons/BackgroundOne"

const FourSquareLinks = ({ data }) => {
  return (
    <SectionStyled>
      <div className="wrapper">
        {data.squares.map((link, index) => {
          const imageDisplay = getImage(
            link.image.localFile.childImageSharp.gatsbyImageData
          )
          const imageAlt = link.image.altText
          return (
            <DivStyled key={index}>
              <div className="block-image">
                <GatsbyImage
                  image={imageDisplay}
                  alt={imageAlt}
                  layout="fullWidth"
                  formats={["auto", "webp", "avif"]}
                />
              </div>
              <div className="block-title">
                <h2>{link.title}</h2>
              </div>
              <div className="block-content">
                <p>{link.content}</p>
              </div>
            </DivStyled>
          )
        })}
      </div>
      <div className="bg-background">
        <BackgroundOne />
      </div>
    </SectionStyled>
  )
}

const SectionStyled = styled.section`
  position: relative;
  padding-top: 5rem;
  padding-bottom: 10rem;

  .wrapper {
    ${standardWrapper};
  }

  .bg-background {
    position: absolute;
    max-width: 45rem;
    bottom: -37.5rem;
    right: -20rem;
    margin: auto;
    z-index: -1;

    @media (min-width: 768px) {
      bottom: -25rem;
      right: -20rem;
      z-index: -1;
    }

    @media (min-width: 1025px) {
      bottom: -20rem;
      right: -20rem;
    }

    @media (min-width: 1200px) {
      bottom: -25rem;
      right: -15rem;
    }

    @media (min-width: 1300px) {
      bottom: -20rem;
      right: -10rem;
    }
  }
`

const DivStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  width: calc(100%);
  margin: 2rem auto;

  @media (min-width: 768px) {
    width: calc((100% / 2) - 8rem);
    margin: 1rem 4rem;
  }

  @media (min-width: 1025px) {
    width: calc((100% / 2) - 10rem);
    margin: 1rem 5rem;
  }

  .block-image {
    width: 100%;
    margin: 2rem auto;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 1);
    overflow: hidden;

    @media (min-width: 768px) {
    }

    @media (min-width: 1025px) {
      max-width: 15rem;
    }
  }

  .block-title {
    width: 100%;
    text-align: center;

    h2 {
      ${H2Blue};
      transition: all 0.3s ease-out;
    }
  }

  .block-content {
    width: 100%;
    text-align: center;

    p {
      ${B2LightBlue};
    }
  }
`

export default FourSquareLinks
